import React, {useEffect}  from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';

export default function MultiSearch({name, id, options, setValue}) {
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [dropDownOptions, setDropDownOptions] = React.useState([{'label':'temp'}])

  useEffect(() => {
    if(options)
      options.then((data) => {
        setDropDownOptions(data);
        setShowDropDown(true);
      });
  }, [options]);

  return (
    <Box sx={{border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: 4, width: "90%", margin: "auto", marginBottom:"20px"}}>
        <FormControl fullWidth style={{marginBottom:20, width:"60%", marginTop:20}}>
          <InputLabel htmlFor="outlined-adornment-amount">Amount Given</InputLabel>
          <OutlinedInput
            id={id+"_amount"}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label={"Amount Given"}
            type="number"
            size="small"
            style={{margin:"auto"}}
          />
        </FormControl>
        <FormControl fullWidth style={{marginBottom:20}} >
        {!showDropDown
        ?
          <TextField label={"To "+name}  
              InputLabelProps={{
                  shrink: true,
              }} 
              id={id} 
              size="small" 
              style={{margin:"auto",width:'90%'}}
          />
        : 
          <Autocomplete
              multiple
              id={id} 
              options={dropDownOptions}
              onChange={(event, newValue) => {
                setValue(id,newValue);
              }}
              renderInput={(params) => <TextField 
                {...params}
                label={"To "+name}  
                InputLabelProps={{
                    shrink: true,
                }} 
                size="small" 
                style={{margin:'auto', width:'90%'}}
              />}
            />
        }
        </FormControl>
        
    </Box>
  );
}