import React, {useEffect}  from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function KeywordSearch({name, id, options, setValue}) {
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [dropDownOptions, setDropDownOptions] = React.useState([{'label':'temp'}])

  useEffect(() => {
    if(options)
      options.then((data) => {
        setDropDownOptions(data);
        setShowDropDown(true);
      });
  }, [options]);

  return (
    <Box>
        <FormControl fullWidth style={{marginBottom:20}} >
            {!showDropDown
                ?
                <TextField label={name}  
                    InputLabelProps={{
                        shrink: true,
                    }} 
                    id={id} 
                    size="small" 
                    style={{margin:"auto",width:'90%'}}
                />
                : 
                <Autocomplete
                    multiple
                    id={id} 
                    options={dropDownOptions}
                    onChange={(event, newValue) => {
                        setValue(id,newValue);
                    }}
                    renderInput={(params) => <TextField 
                        {...params}
                        label={name}  
                        InputLabelProps={{
                            shrink: true,
                        }} 
                        size="small" 
                        style={{margin:'auto', width:'90%'}}
                    />}
                />
            }
        </FormControl>
    </Box>
  );
}