export default {
    s3: {
      REGION: "us-east-1",
      BUCKET: ""
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: ""
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_kNv2XPslc",
      APP_CLIENT_ID: "3anedltjaco8t0sat59qoi4mvn"
    }
  };