import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function NumericFilter({name, id, defaultValue}) {

  return (
    <Box>
        <FormControl fullWidth style={{marginBottom:20, width:"80%"}}>
          <InputLabel htmlFor="outlined-adornment-amount">{name}</InputLabel>
          <OutlinedInput
            id={id}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            defaultValue={defaultValue}
            label={name}
            type="number"
            size="small"
            style={{margin:"auto"}}
          />
        </FormControl>
        {/*<FormControl fullWidth sx={{width:'13ch'}} style={{marginLeft:5}}>
          <InputLabel htmlFor="outlined-adornment-amount">Max</InputLabel>
          <OutlinedInput
            id="outlined-adornment-max"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Max"
            type="number"
            size="small"
          />
        </FormControl>*/}
    </Box>
  );
}