import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Signup from "./containers/Signup";
import ForceChangePassword from "./containers/ForceChangePassword";
import NotFound from "./containers/NotFound";

export default function Router() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/login/reset" element={<ResetPassword />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/forcechangepassword" element={<ForceChangePassword />}/> 
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}
