import React, {useEffect} from "react";
//import { DataGridPremium } from '@mui/x-data-grid-premium';
import { DataGridPro } from '@mui/x-data-grid-pro';
import $ from 'jquery'; 

/*function createRow(result) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let row = {};
  row['Profile'] = result['photoURL'];
  row['Full Name'] = result['firstName'] + " " + result['lastName'];
  row['Liquid Net Worth'] = formatter.format(result['netWorth']['liquidLower']);
  //row['Net Worth'] = formatter.format(result['netWorth']['netWorthLower']);
  row['Email'] = result['businessContact']['email'];
  var total = result['amountToDem']+result['amountToRep']
  let toDems = (result['amountToDem']/total)*100;
  let toReps = (result['amountToRep']/total)*100;
  row['Giving by Party'] = {'toDems': toDems ? toDems : 0, 'toReps': toReps ? toReps : 0};
  row['Party Giving'] = formatter.format(total)
  //row['Dem Donations'] = formatter.format(result['amountToDem'])
  //row['Rep Donations'] = formatter.format(result['amountToRep'])
  row['Age'] = result['age'];
  //row['Marital Status'] = result['maritalStatus'];
  //row['Primary Company'] = result['primaryCompany'];
  //row['Position'] = result['position'];
  row['DossierID'] = result['ID']

  return row;
}*/

export default function DataTable({columns, rows, rowCount, filters, refresh, apiUrl, createRow}) {
  const [page, setPage] = React.useState(1);
  const [loadedRows, setLoadedRows] = React.useState([]);
  const [bankedRows, setBankedRows] = React.useState([]);
  //const [loading, setLoading] = React.useState([]);

  useEffect(() => {
    setLoadedRows(rows);
    let params = null;
    handleOnRowsScrollEnd(params);
  }, [rowCount]);

  useEffect(() => {
    if(refresh) {
      setPage(1);
      setLoadedRows(rows);
      setBankedRows([]);
      console.log("refresh");
    }
  }, [refresh]);

  function handleOnRowsScrollEnd(params) {
    if(rowCount !== 0){
      if(bankedRows.length !== 0){
        setLoadedRows(loadedRows.concat(bankedRows));
      }
      let newPage = page+1;
      setPage(newPage);
      (async () => {
        var apigatewayendpoint = apiUrl+'/get-wealthx-page';
        let response = await $.get(apigatewayendpoint, {'page': newPage, 'filters': JSON.stringify(filters)}, 'json');

        let results = response['searchResult']['hits'];

        let rows = [];
        for (let i = 0; i < results.length; i++) {
          //console.log(results[i]);
          rows.push(createRow(results[i]));
          rows[i]['id'] = i + (page*500);
        }
        setBankedRows(rows);
      })();
      setPage(newPage);
    }
  }
    
  return (
    <div style={{ height:'100%', width: '100%' }}>
      {/*<DataGridPremium
        rows={rows}
        columns={columns}
        pageSize={100}
        disableSelectionOnClick
      />*/}
      <DataGridPro
        columns={columns}
        rows={loadedRows}
        rowCount={rowCount}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        loading={refresh}
      />
    </div>
  );
}