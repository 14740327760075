import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Box from '@mui/material/Box';
import logo from "../images/logo.png";
import "./Signup.css";

export default function ForceChangePassword() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    tempPassword: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const { userHasAuthenticated } = useAppContext();

  function containsNumbers(str) {
    return /[0-9]/.test(str);
  }

  function containsSpecialChars(str) {
    const specialChars =
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function containsUppercase(str) {
    return /[A-Z]/.test(str);
  }

  function containsLowercase(str) {
    return /[a-z]/.test(str);
  }

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.tempPassword.length > 0 &&
      fields.password.length >= 8 &&
      containsNumbers(fields.password) &&
      containsSpecialChars(fields.password) &&
      containsUppercase(fields.password) &&
      containsLowercase(fields.password) &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    //setIsLoading(true);
  
    try {
      //await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.tempPassword)
        .then((user) => {
            Auth.completeNewPassword(
                user, // the Cognito User Object
                fields.password, // the new password
                // OPTIONAL, the required attributes
            )
            .then((user) => {
              userHasAuthenticated(true);
              navigate("/");
            })
            .catch((e) => {
            console.log(e);
            });
        })
        .catch((e) => {
            console.log(e);
        });
    } catch (e) {
      onError(e);
      //setIsLoading(false);
    }
  }

  function renderForm() {
    return (
          <form onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={fields.email}
                onChange={handleFieldChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="tempPassword"
                label="Temporary Password"
                type="password"
                id="tempPassword"
                autoComplete="current-password"
                value={fields.tempPassword}
                onChange={handleFieldChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={fields.password}
                onChange={handleFieldChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={fields.confirmPassword}
                onChange={handleFieldChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!validateForm()}
              >
                Change Password
              </Button>
              <Box>
                <ListItem sx={{padding: '10px 0px 0px 25px'}}>
                  <ListItemIcon >
                    {fields.password.length >= 8 ? <CheckCircleRoundedIcon sx={{color:'green'}} /> : <CancelRoundedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary="At least 8  characters"
                  />
                </ListItem>
                <ListItem sx={{padding: '0px 0px 0px 25px'}}>
                  <ListItemIcon >
                    {containsNumbers(fields.password) ? <CheckCircleRoundedIcon sx={{color:'green'}} /> : <CancelRoundedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains at least 1 number"
                  />
                </ListItem>
                <ListItem sx={{padding: '0px 0px 0px 25px'}}>
                  <ListItemIcon>
                    {containsSpecialChars(fields.password) ? <CheckCircleRoundedIcon sx={{color:'green'}} /> : <CancelRoundedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains at least 1 special character"
                  />
                </ListItem>
                <ListItem sx={{padding: '0px 0px 0px 25px'}}>
                  <ListItemIcon>
                    {containsUppercase(fields.password) ? <CheckCircleRoundedIcon sx={{color:'green'}} /> : <CancelRoundedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains at least 1 uppercase letter"
                  />
                </ListItem>
                <ListItem sx={{padding: '0px 0px 0px 25px'}}>
                  <ListItemIcon>
                    {containsLowercase(fields.password) ? <CheckCircleRoundedIcon sx={{color:'green'}} /> : <CancelRoundedIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary="Contains at least 1 lowercase letter"
                  />
                </ListItem>
              </Box>
          </form>
            
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <div className="Logo">
            <img src={logo} alt="Logo" />
        </div>
      {renderForm()}
      </div>
    </Container>
  );
}