import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import { Auth } from "aws-amplify";
import Router from "./Router";
//import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Signup from "./containers/Signup";
import NotFound from "./containers/NotFound";
import "./App.css";

/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));*/

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const navigate = useNavigate();
  //const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {

      try {
        await Auth.currentSession();
          
        userHasAuthenticated(true);
        navigate("/");
      }
      catch(e) {
        navigate("/login");
        if (e !== 'No current user') {
          onError(e);
        }
      }
      setIsAuthenticating(false);
  }

  const handleHome = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleLogout() {
    await Auth.signOut();
  
    handleClose();
    userHasAuthenticated(false);
    navigate("/login");
  }

  return (
    !isAuthenticating &&
    <div className="App">
      {isAuthenticated
      ? <div style={{flexGrow: 1}}>
          <AppBar position="static">
            <Toolbar style={{minHeight: 58}}>
              {/*<Typography variant="h4" className={classes.title}>
                <Link to='/'><img style={{marginTop: 10}} alt="Logo" src={logo} /></Link>
              </Typography>*/}
                <div style={{flexGrow: 1}}>
                <IconButton
                    aria-label="home"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleHome}
                    color="inherit"
                  >
                    <HomeIcon className="home" fontSize="large" />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle className="account-circle" />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogout} className="menu-account"><ExitToAppOutlinedIcon/>Logout</MenuItem>
                  </Menu>
                </div>
            </Toolbar>
          </AppBar>
        </div>
      : <></>
      }
      <AppContext.Provider
        value={{ isAuthenticated, userHasAuthenticated }}
      >
       <Router/>
            
    </AppContext.Provider>
    </div>
  );
}

export default App;