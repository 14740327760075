import React, {useEffect} from "react";
import { styled } from '@mui/material/styles';
import DataTable from "../components/DataTable";
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import NumericFilter from "../components/NumericFilter";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import KeywordSearch from "../components/KeywordSearch";
import MultiSearch from "../components/MultiSearch";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import $ from 'jquery'; 
import "./Home.css";

//const API_URL = 'https://wosi7inr3c.execute-api.us-east-1.amazonaws.com/prod'; //DEV API
const API_URL = 'https://bzzw2j1xdi.execute-api.us-east-1.amazonaws.com/prod'; //PROD API

//${({ color }) => color && `color: ${color};`}
const ColoredDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'success',
})(({ total }) => ({
  '&': {
    width:120,
    margin:'auto'
  },
  '&:before': {
    height: '1.5em',
    display: 'block',
    width:total.toDems+'%',
    float:'left',
    content: '""',
    backgroundColor:'blue'
  },
  '&:after': {
    height: '1.5em',
    display: 'block',
    width:total.toReps+'%',
    float:'right',
    content: '""',
    backgroundColor:'red'
  }
}));

export default function Home() {
 
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [rows, setRows] = React.useState([])
    const [columns, setColumns] = React.useState([])
    const [opened, setOpened] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(0);
    const [expand, setExpand] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [filters, setFilters] = React.useState({})
    const [freshSearch, setFreshSearch] = React.useState(false);
    const [exportRunning, setExportRunning] = React.useState(false);
    const [multiSelectProps, setMultiSelectProps] = React.useState({});
    const loading = expand && options.length === 0;
    const menuHeight = window.innerHeight*0.85;
    const applyHeight = menuHeight*0.075;

    useEffect(() => {
      let active = true;
      return () => {
        active = false;
      };
    }, []);

    useEffect(() => {
      if (!expand) {
        setOptions([]);
      }
    }, [expand]);

    async function getDistinctOptions(group, order, table){
      var options = [];
      let params = {'group': group, 'order': order, 'table': table}
      var apigatewayendpoint = API_URL+'/get-wealthx-distinct';
      let response = await $.get(apigatewayendpoint, {'params': JSON.stringify(params)}, 'json');
      let distinct = response['distinct'];
      for(let i = 0; i < distinct.length; i++) {
        options.push({label: distinct[i]});
      }
      return options;
    }

    async function getOptions(name) {

      if(name === 'philanthropy'){
        let group = 'philanthropy:causeName'
        let order = 'philanthropy:amount'
        let table = 'philanthropies'
        let options = await getDistinctOptions(group, order, table);
        return options;
      }
      if(name === 'political_giving'){
        let group = 'political_giving:entityName'
        let order = 'political_giving:giftAmount'
        let table = 'political_givings'
        //let options = await getDistinctOptions(group, order, table);
        let options = [{label:'Republican Party'},{label:'Democratic Party'},{label:'Unknown'},{label:'Independent'},{label:'Libertarian Party'}]
        return options;
      }
      if(name === 'state'){
        let group = 'residence:state'
        let order = ''
        let table = 'residences'
        let options = await getDistinctOptions(group, order, table);
        return options;
      }
      if(name === 'industry'){
        let group = 'position:industryTypeName'
        let order = ''
        let table = 'positions'
        let options = await getDistinctOptions(group, order, table);
        return options;
      }
      return [];
    }

    function getFilterObj(id, selc){
        let temp = document.getElementById(id).value;
        if(temp){
          //console.log(temp);
          let dict = {};
          dict['value'] = temp;
          dict['select'] = selc;
          return dict;
        }
        return null;
    }

    function updateFilters() {
        let min_liquid = document.getElementById('min_liquid').value;
        filters['min_liquid'] = min_liquid;
        filters['email'] = document.getElementById('email_required').checked;
        //SET INCLUDES TEXT FILTER
        let includes_text_search = getFilterObj('includes_text_search', 'includes');
        if(includes_text_search)
          filters['includes_text_search'] = includes_text_search;
        else
          delete filters['includes_text_search'];
        //SET EXCLUDES TEXT FILTER
        let excludes_text_search = getFilterObj('excludes_text_search', 'excludes');
        if(excludes_text_search)
          filters['excludes_text_search'] = excludes_text_search;
        else
          delete filters['excludes_text_search'];
        //SET PHILANTHROPY CAUSE FILTER
        let philanthropy_value = multiSelectProps["philanthropy"];
        let philanthropy_amount = document.getElementById("philanthropy_amount").value;
        if(philanthropy_value && philanthropy_amount)
          filters['philanthropy_search'] = {'value': philanthropy_value, "amount": philanthropy_amount};
        else
          delete filters['philanthropy_search'];
        //SET POLITICAL ENTITY FILTER
        let political_value = multiSelectProps["political"];
        let political_amount = document.getElementById("political_amount").value;
        if(political_value && political_amount){
          console.log(political_value);
          if(political_value.includes('Democratic Party')){
            political_value += ',Minnesota Democratic-Farmer-Labor Party (DFL)';
          }
          filters['political_search'] = {'value': political_value, "amount": political_amount};
        }else 
          delete filters['political_search'];
        //SET STATE FILTER
        let state_value = multiSelectProps["state"];
        if(state_value)
          filters['state_search'] = {'value': state_value};
        else 
          delete filters['state_search'];
        //SET INDUSTRY FILTER
        let industry_value = multiSelectProps["industry"];
        if(industry_value)
          filters['industry_search'] = {'value': industry_value};
        else 
          delete filters['industry_search'];
        
    }

    function submitSearch(){

        setFreshSearch(true);
        updateFilters();

        (async () => {
          var apigatewayendpoint = API_URL+'/get-wealthx-page';
          let response = await $.get(apigatewayendpoint, {'page': 1, 'filters': JSON.stringify(filters)}, 'json');

          let totalHits = response['searchResult']['totalHits'];
          console.log(totalHits);
          setRowCount(totalHits);

          let results = response['searchResult']['hits'];
          console.log(results);
          let rows = [];
          let ids = [];
          for (let i = 0; i < results.length; i++) {
            ids.push(results[i]['ID']);
            rows.push(createRow(results[i]));
            rows[i]['id'] = i;
          }
          console.log(ids);
          let columns = [];
          if(rows.length !== 0){
            let keys = Object.keys(rows[0]);
            for (let i = 0; i < keys.length; i++) {
              if(keys[i] !== 'id'){
                if(keys[i] === 'Profile'){
                  columns.push({ field: keys[i], 
                    headerName: keys[i], 
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          <Avatar style={{marginLeft:"40px"}} src={params.row.Profile} />
                        </>
                      );
                    }
                  });
                }else if(keys[i] === 'Full Name'){
                  columns.push({ field: keys[i], 
                    headerName: keys[i], 
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          <Link href={"https://rp.wealthx.com/secure/primarydossier.jsp?profileId="+params.row.DossierID} target="_blank">
                            {params.row['Full Name']}
                          </Link>
                        </>
                      );
                    }
                  });
                }else if(keys[i] === 'Giving by Party'){
                  columns.push({ field: keys[i], 
                    headerName: keys[i], 
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <>
                          <ColoredDiv total={params.row['Giving by Party']}></ColoredDiv>
                        </>
                      );
                    }
                  });
                }else{
                    columns.push({ field: keys[i], headerName: keys[i], width: 150 });
                }
              }
            }
            setColumns(columns);
            setRows(rows);
          }
          setFreshSearch(false);

        })();
    }

    function downloadURI(uri) {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    function submitExtractRequest() {

      updateFilters();

      (async () => {
        setExportRunning(true);
        var apigatewayendpoint = API_URL+'/get-wealthx-export';
        let response = await $.get(apigatewayendpoint, {'filters': JSON.stringify(filters)}, 'json');

        let downloadUrl = response['downloadUrl'];
        //console.log(downloadUrl);
        setExportRunning(false);
        downloadURI(downloadUrl);
      
      })();
    }

    function createRow(result) {

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      let row = {};
      row['Profile'] = result['photoURL'];
      row['Full Name'] = result['firstName'] + " " + result['lastName'];
      row['Liquid Net Worth'] = formatter.format(result['netWorth']['liquidLower']);
      //row['Net Worth'] = formatter.format(result['netWorth']['netWorthLower']);
      row['Email'] = result['businessContact']['email'];
      var total = result['amountToDem']+result['amountToRep']
      let toDems = (result['amountToDem']/total)*100;
      let toReps = (result['amountToRep']/total)*100;
      row['Giving by Party'] = {'toDems': toDems ? toDems : 0, 'toReps': toReps ? toReps : 0};
      row['Party Giving'] = formatter.format(total)
      //row['Dem Donations'] = formatter.format(result['amountToDem'])
      //row['Rep Donations'] = formatter.format(result['amountToRep'])
      row['Age'] = result['age'];
      //row['Marital Status'] = result['maritalStatus'];
      //row['Primary Company'] = result['primaryCompany'];
      //row['Position'] = result['position'];
      row['DossierID'] = result['ID']

      return row;
    }

    function setMultiValues(id, def) {
      //console.log(id, def);
      let searchString = []
      for(let i = 0; i < def.length; i++){
        searchString.push(def[i].label);
      }
      multiSelectProps[id] = searchString.join(',');
      //console.log(multiSelectProps[id]);
    }

  return (
    <div className="Home" style={{paddingLeft:5, paddingRight:0}}>
        {isLoaded
        ?
        <Container maxWidth={false} style={{paddingTop:10}}>
          <Grid container spacing={2}>
            <Grid item md={3} style={{border:'1px solid rgba(224, 224, 224, 1)', borderRadius:'4px', paddingLeft:0}}>
              <Grid container>
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <Button onClick={submitSearch} variant="contained" style={{marginBottom:30}}>Apply Filters</Button>
                </Grid>
                <Grid item md={1}>
                  <IconButton color="primary" onClick={submitExtractRequest} aria-label="Export Data">
                    <Tooltip id="button-report" title="Export Data">
                      <DownloadIcon/>
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item style={{paddingTop:10, overflowY:'auto', overflowX:'hidden', height:(menuHeight-applyHeight-16)}}>
                <Grid container>
                  <Grid item md={8}>
                    <NumericFilter name={"Minimum Liquid Net Worth"} id={"min_liquid"} defaultValue={5000000}/>
                  </Grid>
                  <Grid item md={4}>
                    <FormControlLabel
                      disableTypography
                      sx={{color: 'rgba(0, 0, 0, 0.6)', fontSize:'0.75em'}}
                      label="Require Email"
                      control={
                        <Checkbox
                          id="email_required"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <KeywordSearch name={"Includes Text"} id={"includes_text_search"} />
                <KeywordSearch name={"Excludes Text"} id={"excludes_text_search"} />
                <MultiSearch name={"Philanthropic Cause"} id={"philanthropy"} options={getOptions('philanthropy')} setValue={setMultiValues}/>
                <MultiSearch name={"Political Entity"} id={"political"} options={getOptions('political_giving')} setValue={setMultiValues}/>
                <KeywordSearch name={"State Residence"} id={"state"} options={getOptions('state')} setValue={setMultiValues}/>
                <KeywordSearch name={"Industry Type"} id={"industry"} options={getOptions('industry')} setValue={setMultiValues}/>
              </Grid>
            </Grid>
            <Grid item md={9} style={{paddingTop:0}}>
              <DataTable rows={rows} columns={columns} rowCount={rowCount} filters={filters} refresh={freshSearch} apiUrl={API_URL} createRow={createRow}/>
            </Grid>
          </Grid>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={exportRunning}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
        : <></>
        }
    </div>
  );
}